const tratamento = { '0': 'Sr.', '1': 'Sra.', '2': 'Dr.', '3': 'Dra.' };
const tipoContato = { '0': 'Residencial', '1': 'Comercial', '2': 'Celular', '3': 'WhatsApp' };
const classificacaoServico = { '0': 'Mecânica', '1': 'Estética', '2': 'Revisão', '3': 'Outros' };
const statusConservacao = { '0': 'Bom', '1': 'Regular', '2': 'Ruim' };
const tipoLeilao = { '0': 'Financeira', '1': 'Seguradora', '2': 'Judicial', '3': 'Empresa' };
const tipoVeiculo = { '1': 'Carro', '2': 'Moto' };
const statusUsuario = { '0': 'Inativo', '1': 'Ativo', '2':'Bloqueado' };
const statusFatura = { '0': 'Criada', '1': 'Aberto', '2': 'Pago', '3': 'Cortesia', '4':'Erro' };

export {
    tratamento, tipoContato, classificacaoServico, statusConservacao, tipoLeilao, tipoVeiculo, statusUsuario, statusFatura
}
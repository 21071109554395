<template>
  <section class="card">
    <transition name="modal" mode="in-out">
      <modal v-if="modals.detalhes"
             :title="'Detalhes da avaliação '+itemSelecionado[0].Placa"
             :errors="this.$root.errors"
             :closemodal="closeDetalhesModal"
             :size="'modal-lg'"
             :params="itemSelecionado[0].Id">

        <template v-slot:body="slotProps">
          <detalhes-partial :params="slotProps.params" :avaliacaoId="itemSelecionado[0].Id">

          </detalhes-partial>
        </template>
        <template slot="footer" >

        </template>

      </modal>
      <modal v-if="modals.integrar"
             :title="'Integrar avaliação '+itemSelecionado[0].Id"
             :errors="this.$root.errors"
             :closemodal="closeIntegrarModal"
             :size="'modal-md'"
             :params="itemSelecionado[0].Id"
             @salvar="handleButtonIntegrar">
        <template slot="body">
          <form>
            <div class="row">
              <input type="hidden" v-model="itemSelecionado[0].Id" />
              <div class="form-group col-6">
                <label for="DataVenda">Data Compra</label>
                <input type="date"
                       v-model="itemSelecionado[0].DataCompra"
                       class="form-control form-control-sm"
                       id="DataCompra"
                       name="DataCompra"
                       :max="new Date().toISOString().split('T')[0]" />
              </div>
              <div class="form-group col-6">
                <label for="ValorVenda">Valor Compra</label>
                <money v-model="itemSelecionado[0].ValorCompra"
                       class="form-control form-control-sm"
                       id="ValorCompra"
                       name="ValorCompra"></money>
              </div>
            </div>

          </form>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="pull-right btn btn-sm pull-left btn-primary" @click="slotFooterProps.clickHandler('salvar')">Integrar</button>
        </template>
      </modal>
    </transition>
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header" >
        <span class="selecionado">
          <b>{{itemSelecionado.length ? itemSelecionado[0].Placa : ''}} </b>
          <span>{{itemSelecionado.length ? itemSelecionado[0].Descricao : ''}}</span>
        </span>
      </template>

    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar"></filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar"
                  :title="''"
                  :resolveStatusClass="resolveStatusClass"
                  :classTableContainer="''"
                  :footerContent="''">
      </data-table>
    </div>

  </section>
</template>

<script>
import _consultar from '@/services/consultar.js' ;
import api from '@/services/api.js';
import detalhes from "@/views/AvaliacaoDetalhes.vue";

//AVALIACAO

export default {

    components: {
        ..._consultar.components,
        'detalhes-partial': detalhes,
    },
    data() {
        const that = this;
        return {
            ..._consultar.data(),

            url: '/avaliacoes/index',
            modals: {
                integrar: false, consultar: false, detalhes:  false
            },
            actionButtons: [
                {
                    text: 'Aprovar',
                    className: 'aprovar padrao',
                    icon: 'fas fa-check',
                    iconColor: 'green',
                    color: 'default',
                    visible: false,// inicial
                    visibleOnRowSelected: false,
                    title: 'Aprovar avaliação selecionada',
                    callback: that.handleButtonAprovar
                },
                {
                    text: 'Reprovar',
                    className: 'reprovar padrao',
                    icon: 'fas fa-ban',
                    color: 'default',
                    iconColor: 'red',
                    visible: false,// inicial
                    visibleOnRowSelected: false,
                    title: 'Reprovar avaliação selecionada',
                    callback: that.handleButtonReprovar
                },
                {
                    text: 'Integrar',
                    className: 'integrar padrao',
                    icon: 'fas fa-car',
                    //iconColor: '#5bc0de',
                    color: 'default',
                    visible: false,// inicial
                    visibleOnRowSelected: false,
                    title: 'Integrar veículo no estoque',
                    callback: that.openIntegrarModal
                },
                {
                    text: 'Detalhes',
                    className: 'detalhes padrao',
                    icon: 'fas fa-eye',
                    color: 'default',
                    visible: false,// inicial
                    visibleOnRowSelected: true,
                    title: 'Detalhes da avaliação',
                    callback: function () {
                        that.handleButtonDetalhes();
                    }
                },
                {
                    text: '',
                    className: 'novo padrao',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: true,
                    title: 'Incluir nova avaliação',
                    callback: function () {
                        that.$router.push('/avaliacoes/create');
                    }
                },
                {
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.exportar
                },
            ],
            params: {
                page: 1, //pag inicial
                order: 'Id',
                direction: 'desc',
                perPage: 20 //qtd registros por paginas,

            },
            filtros:
            {
                data: [
                    {
                        id: 'placa',
                        title: 'Placa',
                        active: false,
                        placeholder: '',
                        value: []
                    },
                    {
                        id: 'status',
                        title: 'Status',
                        active: false,
                        value: [],
                        source: [
                            {
                                id: 'status',
                                title: 'Pendente',
                                value: 0
                            },
                            {
                                id: 'status',
                                title: 'Aprovada',
                                value: 1
                            },
                            {
                                id: 'status',
                                title: 'Reprovada',
                                value: 2
                            }
                        ],
                    }
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todas avaliações',
                        filtro: []
                    },
                    {
                        id: 1,
                        title: 'Avaliações Pendentes',
                        filtro:
                            [
                                {
                                    id: 'status',
                                    value: [0],
                                },
                            ]
                    },
                    {
                        id: 2,
                        title: 'Avaliações Aprovadas',
                        filtro:
                            [
                                {
                                    id: 'status',
                                    value: [1],
                                },
                            ]
                    },
                    {
                        id: 3,
                        title: 'Avaliações Reprovadas',
                        filtro:
                            [
                                {
                                    id: 'status',
                                    value: [2],
                                },
                            ]
                    }
                ],
                visaoSelecionada: 0,
                visible: true
            },
            consulta: {placa: '', resultado: []},
            clone: '',
        };
    },
    methods: {
        ..._consultar.methods,
        
        closeDetalhesModal: function () {
            this.modals.detalhes = false;
        },
        closeIntegrarModal: function () {
            this.modals.integrar = false;
        },

        closeConsultarModal: function () {
            this.modals.consultar = false;
        },

        resolveStatusClass: function (status) {
            switch (status) {
                case 'Pendente': return 'warning';
                case 'Aprovada': return 'success';
                case 'Reprovada': return 'danger';
                default:
                    return 'info';
            }
        },
        changeCheckboxCallback: function (item) {

            let aprovarBtn = this.actionButtons.filter(b => b.text === 'Aprovar')[0];
            let reprovarBtn = this.actionButtons.filter(b => b.text === 'Reprovar')[0];
            let estoqueBtn = this.actionButtons.filter(b => b.text === 'Integrar')[0];
            let detalhesBtn = this.actionButtons.filter(b => b.text === 'Detalhes')[0];

            if (item) {
                this.selecionado = `${item.Placa} selecionado`;
            }
            else {
                this.selecionado = '';
            }
            let st = item ? item.StatusAvaliacao : -1;
            debugger;
            switch (st) {
                case 0:
                    aprovarBtn.visible = true;
                    reprovarBtn.visible = true;
                    estoqueBtn.visible = false;
                    break;
                case 1:
                    aprovarBtn.visible = false;
                    reprovarBtn.visible = false;
                    estoqueBtn.visible = true;
                    break;
                default:
                    aprovarBtn.visible = false;
                    reprovarBtn.visible = false;
                    estoqueBtn.visible = false;


            }
            if (item) {
                detalhesBtn.visible = true;
            }
            else {
                detalhesBtn.visible = false;
            }

        },
        openConsultaModal: function () {
            this.consulta.placa = '';
            this.consulta.resultado = [];

            this.modals.consultar = true;
        },
        openIntegrarModal: function () {
            this.modals.integrar = true;
            this.itemSelecionado[0].ValorCompra = this.itemSelecionado[0].ValorAvaliado;
            let dtCompra = this.itemSelecionado[0].DataCompra;
            this.itemSelecionado[0].DataCompra = dtCompra ? dtCompra.substr(0, dtCompra.indexOf('T')) : null;
        },
        handleButtonDetalhes: function () {
            this.modals.detalhes = true;

        },
        handleButtonAprovar: function () {
            let that = this;
            bootbox.confirm({
                message: 'Confirma APROVAÇÃO desta avaliação?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/avaliacoes/aprovar', {
                                id: that.selected[0]
                            })
                            .then(response => {
                                if (response.data.success) {
                                    that.itemSelecionado[0].Status = 'Aprovada';
                                    that.itemSelecionado[0].StatusAvaliacao = 1;
                                    that.changeCheckboxCallback(that.itemSelecionado[0]);
                                }
                            });
                    }
                }
            });
        },
        handleButtonReprovar: function () {
            let that = this;
            bootbox.confirm({
                message: 'Confirma REPROVAÇÃO desta avaliação?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/avaliacoes/reprovar', {
                                id: that.selected[0]
                            })
                            .then(response => {
                                if (response.data.success) {
                                    that.itemSelecionado[0].Status = 'Reprovada';
                                    that.itemSelecionado[0].StatusAvaliacao = 2;
                                    that.changeCheckboxCallback(that.itemSelecionado[0]);
                                }
                            });
                    }
                }
            });
        },

        handleButtonIntegrar: function () {
            let that = this;
            this.$root.errors = [];
            if (!this.itemSelecionado[0].DataCompra) {
                this.$root.errors.push('Digite uma data de compra válida.');
                return;
            }
            bootbox.confirm({
                message: 'Confirma INTEGRAÇÃO desta avaliação?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/avaliacoes/integrar', {
                                Id: that.selected[0],
                                DataCompra: that.itemSelecionado[0].DataCompra,
                                ValorCompra: that.itemSelecionado[0].ValorCompra,
                            })
                            .then(response => {
                                if (response.data.success) {
                                    that.itemSelecionado[0].Status = 'Integrada';
                                    that.itemSelecionado[0].StatusAvaliacao = 3;
                                    that.changeCheckboxCallback(that.itemSelecionado[0]);
                                    let value = parseInt($(veiculosperiodo).val());
                                    value = value++;
                                    $(veiculosperiodo).val(value);
                                    that.modals.integrar = false;
                                }
                            });
                    }
                }
            });
        },
        handleConsultarIntegracao: function () {
            let that = this;
            api.get(`/integracao/consultaavaliacao?placa=${this.consulta.placa}`)
                .then(response => {
                    if (response.data.success) {
                        that.consulta.resultado = response.data.rows;
                    }
                    else {
                        bootbox.alert({
                            message: response.data.msg,
                            backdrop: true,
                            buttons: {
                                ok: {
                                    label: 'OK',
                                    className: 'btn-primary btn btn-sm'
                                }
                            }
                        });
                    }
                });
        },
        integrarERP: function (avaliacao) {
            let that = this;
            api
                .post('/integracao/cadastraravaliacao', {
                    Id: avaliacao.IDINTEGRACAO
                })
                .then(response => {
                    if (response.data.success) {
                        that.result.Records.splice(0, 0, response.data.obj);
                        that.modals.consultar = false;
                        avaliacao.integrado = true;
                    }
                });
        }
    },
    mounted: _consultar.mounted
};
</script>

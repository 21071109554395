<template>
  <div>
    <template v-if="obj.estoque">
      <h4 class="text-primary">Estoque</h4>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Data Compra</label>
            <div>
              <span>
                {{obj.estoque.DataCompra}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Data Entrada</label>
              <div>
                <span>{{obj.estoque.DataEntrada}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Data Pronto</label>
              <div>
                <span>{{obj.estoque.DataPronto}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Total despesas</label>
              <div>
                <span>{{obj.estoque.Estoque.TotalAprovadoDisplay}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Localização</label>
              <div>
                <span>{{obj.estoque.Localizacao}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Status</label>
              <div>
                <span>{{obj.estoque.Status}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Valor Compra</label>
              <div>
                <span>{{obj.estoque.Estoque.ValorCompraDisplay}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Valor Venda</label>
              <div>
                <span>{{obj.estoque.Estoque.ValorVendaDisplay}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Código integração</label>
              <div>
                <span>{{obj.estoque.IdIntegracao}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-divider"></div>
    </template>
    <div v-if="veiculo && veiculo.Id">
      <h4 class="text-primary">Veículo</h4>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Placa</label>
              <div>
                <span>{{veiculo.Placa}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Kilometragem</label>
              <div>
                <span>{{veiculo.KM}}km</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Renavam</label>
              <div>
                <span>{{veiculo.Renavam}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Passagem por leilão?</label>

            <div>
              <span class="text-danger" v-if="veiculo.Leilao">{{tipoVeiculo[veiculo.TipoVeiculo]+' de LEILÃO '+tipoLeilao[veiculo.TipoLeilao]}}</span>
              <span v-if="!veiculo.Leilao">SEM PASSAGEM POR LEILÃO</span>

            </div>
          </div>
        </div>

      </div>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Marca</label>
            <div>
              <span>{{veiculo.Modelo.Marca.Descricao}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Modelo</label>
            <div>
              <span>{{veiculo.Modelo.Descricao}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Ano Modelo</label>
            <div>
              <span>
                {{veiculo.AnoMod}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group">
            <label class="col-form-label">Ano Fabricação</label>
            <div>
              <span>
                {{veiculo.AnoFab}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group ml-0 mr-0" v-if="obj.avaliacao">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Tabela REF. FIPE</label>
            <div>
              <span>{{obj.avaliacao.TabelaREFFipe}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Valor FIPE</label>
            <div>
              <span>{{obj.avaliacao.ValorFipeDisplay || (obj.avaliacao.ValorFipe && obj.avaliacao.ValorFipe.toFixed(2))}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Valor pretendido(cliente)</label>
            <div>
              <span>{{obj.avaliacao.ValorPretendidoDisplay || (obj.avaliacao.ValorPretendido && obj.avaliacao.ValorPretendido.toFixed(2))}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Valor Avaliado</label>
            <div>
              <b class="text-danger">{{obj.avaliacao.ValorAvaliadoDisplay || (obj.avaliacao.ValorAvaliado && obj.avaliacao.ValorAvaliado.toFixed(2))}}</b>
            </div>
          </div>
        </div>
      </div>
      <div class=" row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label>Cor</label>
              <div>
                <span>{{veiculo.CorDisplay || (obj.avaliacao && obj.avaliacao.CorId)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label>Combustivel</label>
              <div>
                <span>{{veiculo.CombustivelDisplay || (obj.avaliacao && obj.avaliacao.CombustivelId)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label>Carroceria</label>
              <div>
                <span>{{veiculo.CarroceriaDisplay  || (obj.avaliacao && obj.avaliacao.CarroceriaId)}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label>Transmissão</label>
              <div>
                <span> {{veiculo.TransmissaoDisplay || (obj.avaliacao && obj.avaliacao.TransmissaoId)}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Chassi</label>
            <div>
              <span> {{veiculo.Chassi}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-divider"></div>
      <h4 class="text-primary">
        Opcionais
      </h4>
      <div class="row form-group ml-0 mr-0 row-opcionais">
        <template v-for="item in veiculo.Opcional.length ? veiculo.Opcional : (obj.avaliacao && obj.avaliacao.Opcional)">
          <div class="col-lg-3" :key="item.Id">
            <div class="form-group mb-1">
              <div class="checkbox-custom checkbox-primary">
                <input disabled="disabled"
                       type="checkbox"
                       readonly="readonly"
                       :value="item.OpcionalId"
                       checked="checked">
                <label :for="item.OpcionalId">{{item.Opcional ? item.Opcional.Descricao : item.Descricao}}</label>
              </div>
            </div>
          </div>
        </template>
        
      </div>
      <div class="form-divider"></div>
    </div>

    <template v-if="obj.avaliacao && obj.avaliacao.Id">
      <h4 class="text-primary">Avaliação</h4>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Data avaliação</label>
            <div>
              <span>
                {{obj.avaliacao.DataAvaliacaoDisplay}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Data Validade</label>
              <div>
                <span>{{obj.avaliacao.DataValidadeDisplay}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Data Status</label>
            <div>
              <span>{{obj.avaliacao.DataStatusDisplay}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Status</label>
            <div>
              <span>{{obj.avaliacao.Status}}</span>
            </div>

          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Código integração</label>
              <div>
                <span>{{obj.avaliacao.IdIntegracao}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Avaliador</label>
            <div>
              <span>
                {{obj.avaliacao.Avaliador}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Aprovador</label>
              <div>
                <span>{{obj.avaliacao.Aprovador}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="form-divider"></div>
    </template>
    <template v-if="obj.avaliacao">
      <h4 class="text-primary">Dados Cliente</h4>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Nome</label>
            <div>
              <span>
                {{obj.avaliacao.Cliente && obj.avaliacao.Cliente.Nome}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <div class="form-group">
              <label class="col-form-label">Documento</label>
              <div>
                <span>
                  {{obj.avaliacao.Cliente.DocumentMasked || obj.avaliacao.Cliente.CpfCnpj }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">RG/Inscr.Estadual</label>
            <div>
              <span>
                {{obj.avaliacao.Cliente.RGIE}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label class="col-form-label">Raz.Social/Apelido</label>
            <div>
              <span>{{obj.avaliacao.Cliente.ApelidoRazaoSocial}}</span>
            </div>

          </div>
        </div>
      </div>
      <div class="row form-group ml-0 mr-0">
        <div class="col-lg-2">
          <div class="form-group">
            <label class="col-form-label">Cidade/Estado</label>
            <div>
              <span>
                {{obj.avaliacao.Cliente.CidadeEstado}}
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="form-divider"></div>
      <h4 class="text-primary">
        Contato
      </h4>
      <div class="row form-group ml-0 mr-0" style="max-height: 110px; overflow:auto">
        <div class="col-lg-12">
          <table class="table table-sm table-bordered table-striped">
            <thead>
              <tr>
                <th>Tratar com <span class="text-danger">* </span></th>
                <th>Tratamento</th>
                <th>Tipo</th>
                <th>Setor</th>
                <th>Número <span class="text-danger">* </span></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in obj.avaliacao.Cliente.Contato">
                <tr :key="item.Id">
                  <td>
                    <span>{{item.TratarCom}}</span>
                  </td>
                  <td>
                    <span>{{item.TratamentoDisplay || tratamento[item.Tratamento]}}</span>
                  </td>
                  <td>
                    <span>{{item.TipoContatoDisplay || tipoContato[item.TipoContato]}}</span>
                  </td>
                  <td>
                    <span>{{item.Setor}}</span>
                  </td>
                  <td>
                    <span>{{item.TelefoneMasked || item.Telefone}}</span>
                  </td>
                </tr>
              </template>
              
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-divider"></div>

      <div>
        <h4 class="text-primary">
          Checklist
        </h4>
        <div class="row form-group ml-0 mr-0 row-opcionais">
          <template v-for="checklist in obj.avaliacao.Checklist">
            <blockquote :key="checklist.Id" class="col-lg-3 primary b-thin mb-3 pl-2" >
              <div class="form-group">
                <div class="checkbox-custom checkbox-primary">
                  <input type="checkbox"
                         disabled="disabled"
                         readonly="readonly"
                         checked="checked"
                         :value="checklist.ChecklistId" :id="'checklist-'+checklist.ChecklistId">
                  <label for="'checklist-'+checklist.ChecklistId" class="d-block">{{checklist.Checklist ? checklist.Checklist.Descricao : checklist.Descricao}}</label>

                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-6">
                    <span>{{statusConservacao[checklist.StatusConservacao]}}</span>
                  </div>
                  <div class="col-6">
                    <i>
                      {{checklist.Observacao}}
                    </i>
                  </div>
                </div>
              </div>
            </blockquote>
          </template>
        </div>
        <div class="form-divider"></div>

        <h4 class="text-primary">
          Serviços a executar
        </h4>
        <div class="row form-group ml-0 mr-0">
          <div class="col-lg-12">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th style="width:90px">Código</th>
                  <th>Descrição</th>
                  <th style="width:300px">Classificação</th>
                  <th style="width:150px">Valor</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in obj.avaliacao.ServicoAvaliador">
                  <tr :key="item.Id">
                    <td>
                      <span>{{item.Servico.Id}}</span>
                    </td>
                    <td>
                      <span>{{item.Servico.Descricao}}</span>
                    </td>
                    <td>
                      <span>{{item.Servico.Classificacao || classificacaoServico[item.Servico.ClassificacaoServico]}}</span>
                    </td>
                    <td>
                      {{item.ValorDisplay || item.Valor.toFixed(2)}}
                    </td>
                  </tr>

                </template>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3"><b class="pull-right">Total</b></td>
                  <td><b class="text-danger">{{obj.avaliacao.ValorPreparacaoDisplay || Total}}</b></td>

                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="form-divider"></div>

        <h4 class="text-primary">
          Fotos
        </h4>
        <div class="row form-group ml-0 mr-0" v-if="obj.avaliacao.Id">
          <template v-for="item in obj.avaliacao.Imagem">
            <blockquote :key="item" class="info b-thin col-lg-3  mb-3">
              <div class="row">
                <div class="col-5">
                  <a :href="item.Path" target="_blank">
                    <img :src="item.Path" class="w-100" />
                  </a>
                </div>
                <div class="col-7">
                  <div class="form-group">
                    <span>{{item.Descricao}}</span>
                  </div>
                </div>
              </div>
            </blockquote>
          </template>
        </div>
        <div class="row form-group ml-0 mr-0" v-if="!obj.avaliacao.Id">
          <template v-for="f in obj.avaliacao.files">
            <blockquote class="info b-thin col-lg-3  mb-3" :key="f">
              <div class="row">
                <div class="col-5">
                  <img :src="f.src" class="w-100" />
                </div>
                <div class="col-7">
                  <div class="form-group">
                    <label class="col-form-label">{{f.FileNameAbrv}}</label>
                    <div>
                      <b>{{f.Descricao}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </blockquote>

          </template>
        </div>
        <div class="form-divider"></div>

        <h4 class="text-primary">
          Observação
        </h4>
        <div class="row form-group ml-0 mr-0">
          <div class="col">
            <div class="form-group">
              <i>
                {{obj.avaliacao.Observacao}}
              </i>
            </div>
          </div>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/services/api.js';
import { tratamento, tipoContato, classificacaoServico, statusConservacao,tipoLeilao,tipoVeiculo } from '@/services/enums.js';

export default {
    data() {
        return {
            tratamento: tratamento,
            tipoContato: tipoContato,
            classificacaoServico: classificacaoServico,
            statusConservacao: statusConservacao,
            tipoLeilao: tipoLeilao,
            tipoVeiculo: tipoVeiculo,
            obj: {},
            veiculo: {}
        };
    },
    methods: {
    },
    props: ['params', 'avaliacaoId','estoqueId'],
    beforeMount: function () {
        let that = this;
        if (isNaN(this.params)) {
            this.obj = this.params;
        }
        else {
            api.get(`/avaliacoes/details?avaliacaoId=${that.avaliacaoId || null}&estoqueId=${that.estoqueId || null}`)
                .then(response => {
                    debugger;
                    that.obj = response.data;
                    that.veiculo = that.obj.avaliacao ? that.obj.avaliacao.Veiculo : that.obj.estoque ? that.obj.estoque.Estoque.Veiculo : null;
                });
        }
        
    },
    computed: {
        Total: function () {
            return this.obj.avaliacao.ServicoAvaliador.reduce((a, b) => a + b.Valor || 0, 0).toFixed(2);
        }
    },
};
</script>
